<template>
  <div>
    <el-dialog title="移动"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="300px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="60px">
        <el-row>
          <el-form-item label="版块:">
            <el-select v-model="form.board_id"
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in plateList"
                         :key="item.board_id"
                         :label="item.board_name"
                         :value="item.board_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="move">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      plateList: [],
      dialogVisible: false,
      form: {},
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    getPlateList () {
      this.$http({
        url: '/api/v1/discuss/move_board_lst',
        method: 'get',
      }).then(res => {
        this.plateList = res.data
      })
    },
    move () {
      this.$http({
        url: '/api/v1/discuss/move_board',
        method: 'post',
        data: {
          board_id: this.form.board_id,
          discuss_id: this.form.discuss_id,
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '移动成功!',
          type: 'success'
        });
        this.handleClose()
        this.$parent.getInfo()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  text-align: center;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>